<template>
  <div class="info-page">
    <app-documentation />

    <!-- <div class="docs">
      <div class="docs__themes">
        <div @click="open('about')" :class="{ active: openContentId === 'about' }" class="docs__theme">Работа приложения</div>
        <div @click="open('integration')" class="docs__theme">Интеграция</div>
        <div class="docs__theme">Работа приложения</div>
        <div class="docs__theme">Работа приложения</div>
        <div class="docs__theme">Работа приложения</div>
        <div class="docs__theme">Работа приложения</div>
        <div class="docs__theme">Работа приложения</div>
      </div>
      <div class="docs__contents">
        <div :class="{ active: openContentId === 'about' }" class="docs__content">
          <div class="docs__text">
            <p>
              Основная задача приложения "Мониторинг товаров" - увеличить лояльность покупателей и прибыль интернет интернет-магазина. Мы достигаем этого за счет
              предоставления возможности покупателю следить за появлением товаров, которые им интересны, в наличии.
            </p>
          </div>
          <div class="docs__title">Какой функционал реализует приложение?</div>
          <div class="docs__text">
            <p>
              Представим классическу ситуацию: посетитель сайта хочет купить товар, но он закончился(
            </p>
            <p>
              В лучше случае, посетитель увидет надпись что товара нет в наличии.
            </p>
          </div>

        </div>
        <div :class="{ active: openContentId === 'integration' }" class="docs__content">2</div>
      </div>
    </div>-->
  </div>
</template>

<script>
import appDocumentation from '../components/app-documentation'
export default {
  name: 'documentation',
  data() {
    return {
      openContentId: ''
    }
  },
  methods: {
    open(id) {
      this.openContentId = id;
    }
  },
  components: {
    'app-documentation': appDocumentation,
  },
}
</script>

<style lang="scss">
.info-page {
  padding: 30px;
}


.docs {
  display: flex;

  &__themes {
    padding: 15px 0;
    width: 300px;
    background-color: #fff;
  }
  &__theme {
    width: 100%;
    display: block;
    padding: 10px 15px;
    color: var(--color-black);

    transition: .5s;

    &:hover {
      background-color: var(--color-gray);
      color: var(--color-dark)
    }
    &.active {
      color: var(--color-border);
      background-color: var(--color-violet);
    }
  }

  &__contents {
    width: calc(100% - 300px - 30px);
    margin-left: 30px;
  }
  &__content {
    display: none;

    &.active {
      display: block;
    }
  }
}
</style>

<template>
  <div class="documentation__wrapper">

    <div class="documentation__preview">
      На данной странице описаны основные принципы взаимодействия между приложением и интернет-магазином на платформе insales,
      а так-же фунционал приложения.
    </div>

    <app-accordion>
      <template v-slot:header>Шаблоны 4 поколения</template>
      <template v-slot:body>
        <div class="documentation__body">
          <p>
            Для магазинов, использующих шаблоны 4 поколения, реализована интеграция из коробки для всех виджетов карточки-товара.
            После установки и активации приложения в ваш магазин будет добавлен скрипт, благодрая которому, реализуется интеграция.
          </p>
          <p>
            При открытии страниц товара или при переключении вариантов товаров, скрипт проверяет его наличие в бек-офисе.
            В случае если товар находится в наличии - вы не увидите изменений. Если товар отсутствует в наличии - вместо кнпоки "Добавить в корзину"
            будет подставлена кнопка "Следить за наличием", при нажатии на которую откроется форма подписки.
          </p>
        </div>
      </template>
    </app-accordion>
    <app-accordion>
      <template v-slot:header>Разработчикам</template>
      <template v-slot:body>
        <div class="documentation__body">
          <p>
            После активаци приложения к вашему интернет-магазину будет подключен скрипт. Засчет этого на странице товара доступны два метода.
          </p>

          <p>ВНИМАНИЕ. Оба метода автоматически определяют данные товара и работают с выбранным в момент их вызова вариантом товара.</p>

          <p style="font-size: 20px; font-weight: 600">1. Создание подписки</p>

          <p>
            Для создания подписки на товар используйте метод <b>window.pnn.send('test@ya.ru')</b>. Метод принимает <b>один аргумент - email</b> получателя.
          </p>


          <p style="font-size: 20px; font-weight: 600">2. Вызов модального окна</p>


          <p>
            Для вызова модального окна достаточно добавить к кнопке класс 'pnn-btn-js-open-modal'. Также добавьте ко всем дочерним элементам стиль 'pointer-events: none;'
          </p>
        </div>
      </template>
    </app-accordion>
    <app-accordion>
      <template v-slot:header>Создание подписок в приложении</template>
      <template v-slot:body>
        <div class="documentation__body">
          <p>
            Данный функционал позволяет создать "подписку на наличие" на любой товар, находящийся в вашем интернет-магазине прямо в интерфейсе приложения.
          </p>
          <p>Это удобно, если покупатьль розницы просит вас сообщить ему, когда товар появится в наличии. Вы можете быстро создать подписку прямо внутри приложения.
            Поле "точка контакта" является не обазательным. Рекоммендуем использовать это поле если у вас несколько роздничных магазинов. В таком случае в разделе
            "аналитика" вы сможете видеть результаты по подпискам конкретных точек.</p>
        </div>
      </template>
    </app-accordion>
    <app-accordion>
      <template v-slot:header>Настройки формы</template>
      <template v-slot:body>
        <div class="documentation__body">
          <p>
            Для настройки формы (модальное окно/попап) существует набор полей, меняя которые вы сможете настроить
            форму в соответствии с внешним видом своего сайта, без технических знаний.
          </p>
          <p>
            Изменения формы отображаются в реальном времени в интерфейсе приложения.
          </p>
          <p>
            ВНИМАНИЕ. Изменения отображаются только после сохранения настроек.
          </p>
          <p>
            Если вы не хотите использовать стандартную форму, предоставляемую приложением, см. информацию в разделе "Разработчикам"
          </p>
        </div>
      </template>
    </app-accordion>
    <app-accordion>
      <template v-slot:header>Письма и шаблоны</template>
      <template v-slot:body>
        <div class="documentation__body">
          <p style="font-size: 20px; font-weight: 600">1. Шаблон письма</p>

          <p>
            Для отправки письма с уведомлением о поступлении товара используется шаблон. В заранее определенное место в шаблоне
            подставляется перечень товаров, соответствующих двум условиям. Первое - товар находится в наличии в интернет-магазине,
            второе - клиент подписался на данный товар.
          </p>

          <p>
            Список шаблонов, доступных к использованию, представлен в разделе "Шаблоны писем". Приложение предоставляет базовый шаблон, используемый при отправке письма.
            Но вы можете создать любое количество кастомных шаблонов. Для создания шаблона необходимо иметь базовые навыки html-верстки писем.
            Обязательным параметром любого письма является переменная {{ <b>productList</b> }} вместо который будет подставлен перечень товаров.
            При редактировании html кода, результат будет отображаться в реальном времени. Использоваться при отправке писем будет АКТИВНЫЙ шаблон.
          </p>

          <p style="font-size: 20px; font-weight: 600">2. Продукты в письме</p>


          <p>
            Подставное значение представляет из себя список ссылок на страницу (карточку товара) интернет-магазина. Текст ссылки - название товара с указанием опций.
            При переходе по ссылке откроется карточка товара с заранее выбранными опциями, соответстующими тем, на которые подписался клиент.
            Кроме того ссылка содержит 2 utm-метки <b>"utm_pnn_app"</b> со значением "pnn_app" и <b>"utm_pnn_app"</b> со значением id текущего варианат.
          </p>
        </div>
      </template>
    </app-accordion>

  </div>
</template>

<script>
import Vue from 'vue';

import appAccordion from '../components/base-ui/app-accordion';

export default Vue.extend({
  name: 'app-documentation',
  components: {
    'app-accordion': appAccordion,
  }
})

</script>

<style lang="scss">
.documentation {
  &__preview {
    margin-bottom: 20px;
    max-width: 700px;
  }
}
</style>

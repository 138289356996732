<template>
  <div class="accordion" :class="{ 'active': isOpen }">
    <div @click="toggle" class="accordion__header">
      <slot name="header"></slot>
      <div class="accordion__arrow">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z"/>
        </svg>
      </div>
    </div>
    <div class="accordion__body">
      <slot name="body"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'app-accordion',
  data: function () {
    return {
      isOpen: false,
    }
  },
  props: {
    isOpenProps: {
      type: Boolean,
    }
  },
  methods: {
    open() {
      this.isOpen = true;
    },
    close() {
      this.isOpen = false;
    },
    toggle() {
      this.isOpen = ! this.isOpen;
    }
  },
  mounted() {
    if (this.isOpenProps) {
      this.isOpen = true;
    }
  }
}
</script>

<style lang="scss">
.accordion {
  margin-bottom: 10px;

  &.active {
    & .accordion__arrow svg {
      transform: rotate(90deg);
      transition: .5s;
    }
    & .accordion__body {
      height: auto;

      transition: .5s height;

      padding: 80px 30px 30px 30px;

      margin-top: -50px;

      border: 2px solid var(--color-border);

      border-radius: 30px;

      background: #fff;
    }
  }

  &__header {
    padding: 0 30px;
    min-height: 50px;
    max-height: 50px;

    position: relative;
    z-index: 2;

    display: flex;
    align-items: center;
    justify-content: space-between;

    background: var(--color-border);
    border-radius: 30px;

    font-weight: 600;
    font-size: 16px;

    color: #000000;
  }

  &__body {
    overflow: hidden;

    height: 0;

    padding: 0;

    transition: .5s height;
  }

  &__arrow svg {
    transition: .5s;

    width: 14px;

    fill: #727272;

    display: block;
  }
}
</style>
